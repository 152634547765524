
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBxKnk2yel8gpv-gbK_5ujmQRMHqMAOZLM",
  authDomain: "app-of-questions.firebaseapp.com",
  projectId: "app-of-questions",
  storageBucket: "app-of-questions.appspot.com",
  messagingSenderId: "213366221265",
  appId: "1:213366221265:web:d0849ae3e956fa1f3518f0",
  measurementId: "G-2SEL5P9MJE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db=getFirestore(app);