
import './style.css';
import AddQuestion from './components/AddQuestion';

function App() {
  return (
    <div className="App">
       <h1 id='title'>APP DER ANWORTEN</h1>
       <AddQuestion/>
    </div>
  );
}

export default App;
