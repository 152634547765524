import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { db } from '../config/firebase';
import { collection, addDoc, writeBatch, getDocs, doc } from 'firebase/firestore';
import ReactLoading from 'react-loading';
import 'react-notifications/lib/notifications.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddQuestion = () => {
  const [inputFields, setInputFields] = useState([{ id: '', answer: '' }]);
  const [loading,setLoading]=useState(false);
  let maxId = 0;

  

  const answerCollectionRef = collection(db, 'answers');
  const batch = writeBatch(db);
  const notifySuccess = () => toast.success("Antworten erfolgreich eingefügt");
  const notifyError = () => toast.error("fehlgeschlagen! Versuchen Sie es erneut.");
  const error=false;
  const getMaxID = async () => {
    try {
      const data = await getDocs(answerCollectionRef);
      const filteredData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      filteredData.forEach((doc) => {
        console.log(doc.id)
        if (parseInt(doc.id) > parseInt(maxId)) {
          maxId = doc.id;
        }
      });
    } catch (err) {
      console.error(err);
    }
    console.log(maxId);
  };

  const addFields = () => {
    let newField = { id: '', answer: '' };
    setInputFields([...inputFields, newField]);
  };

 

  const handleFormChange = (index, event) => {
   
    console.log(maxId)
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    data[index]['id'] =(index + 1);
    setInputFields(data);
  };

  const submit = async(e) => {
    setLoading(true);
    e.preventDefault();
    await getMaxID();
    console.log(inputFields);
    inputFields.forEach((field) => {
        if(field.answer!=''&&field.answer!=null ){
            const docRef = doc(answerCollectionRef, (parseInt(field.id)+parseInt(maxId)).toString()); // Create document reference
            batch.set(docRef, { answer: field.answer });
        }
      
    });

    batch
      .commit()
      .then(() => {
        console.log('Batch write successful!');
        window.location.reload();
        setLoading(false);
        error=false;
      })
      .catch((error) => {
        console.error('Error performing batch write:', error);
        setLoading(false);
        error=true;
      });

      error?notifyError():notifySuccess();
  };

  return (
    <div id="add-question">
       {loading? <div id='spin'>
        <ReactLoading  type='spin' color='#292826' />
        </div>:<></>}


      { !loading ?<>
      <div id="add-q-title">
        <h2>Antworten hinzufügen</h2>
        <button type="button" className="btn btn-info" onClick={addFields}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <form id="form" onSubmit={submit}>
        {inputFields.map((input, i) => (
          <div key={i} className="input-group mb-3" style={{ width: '70%', border: 'solid rgba(0,0,0,0.3) 1px', borderRadius: '5px' }}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">{i + 1}</span>
            </div>
            <input type="text" name="answer" className="form-control" placeholder={`Answer ${i + 1}`} aria-label="Answer" aria-describedby="basic-addon1" onChange={(e) => handleFormChange(i, e)} />
          </div>
        ))}
      </form>

      <button type="button"  className="btn btn-success" style={{ padding: '5px 30px', fontWeight: 'bold' }} onClick={submit}>
        SAVE
      </button>
     
      </>:<></>}
      <div className='toast-container'>
      <ToastContainer  autoClose={false}  theme="dark"   />
        </div>
     
    </div>
  );
};

export default AddQuestion;
